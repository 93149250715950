/**
 * Managing everything related to folios
 */

import {
  AddFolioClippingModel,
  ContentItemSummaryResult,
  ContentItemType,
  Folio,
  FolioClient,
  FolioContentItemResult,
  GetFolioAttachment,
  RemoveFolioClippingModel,
  UpdateFolio,
} from "@/api/DoceoApi";
import { defineStore } from "pinia";
import Vue from "vue";
import store from "@/store/userStore";

interface SummaryFilters {
  includeContentTypes: ContentItemType[] | null | undefined;
  excludeContentTypes: ContentItemType[] | null | undefined;
}

export const useFolioStore = defineStore("folio", {
  state: () => ({
    storeFolios: [] as ContentItemSummaryResult[],
    folioViewing: null as FolioContentItemResult | null,
    folioLogos: [] as GetFolioAttachment[],
    isFetchingFolios: false, // Keep track of whether a request is currently out to fetch folios. This ensures we only get the folios once if we request multiple times quickly
  }),
  getters: {
    folios: (state) => state.storeFolios as ContentItemSummaryResult[],
    myCreatedFolios: (state) => state.storeFolios.filter((e) => e.creator?.userId == store.getters.userId) as ContentItemSummaryResult[], // Return only folios that the user is the creator of
  },
  actions: {
    async addFolioClipping(folioClippingModel: AddFolioClippingModel) {
      const folioClient = new FolioClient();
      return await folioClient.addFolioClippings(folioClippingModel.folioId, folioClippingModel);
    },
    async removeFolioClipping(folioClippingModel: RemoveFolioClippingModel) {
      const folioClient = new FolioClient();
      return await folioClient.removeFolioClipping(folioClippingModel.folioId, folioClippingModel);
    },
    async getDefaultLogos() {
      let folioClient = new FolioClient();
      if (this.folioLogos.length > 0) {
        return this.folioLogos;
      }
      let logos = await folioClient.getFolioLogo();
      this.folioLogos = logos;
      return await folioClient.getFolioLogo();
    },
    /**
     * Create a new folio
     */
    async deleteFolio(folioId: string) {
      const folioClient = new FolioClient();

      // prettier-ignore
      try {
          await folioClient.deleteFolio(folioId)
          const contentIndex = this.storeFolios.findIndex((x) => x.contentItemId === folioId);
          if (contentIndex > -1) {
            this.storeFolios.splice(contentIndex, 1);
          }
          return Promise.resolve();
        } catch (x: any) {
          if (x && x.status) {
            return Promise.reject(x.status);
          }
          return Promise.reject();
        }
    },
    /**
     * Create a new folio
     */
    async createFolio(newFolio: Folio) {
      const folioClient = new FolioClient();

      // prettier-ignore
      try {
          let result = await folioClient.createFolio(newFolio);
          const contentIndex = this.storeFolios.findIndex((x) => x.contentItemId === result.contentItemId);

          if (contentIndex === -1) {
            this.storeFolios.unshift(result);
          } else {
            Vue.set(this.storeFolios, contentIndex, result);
          }
          return Promise.resolve();
          
        } catch (x: any) {
          if (x && x.status) {
            return Promise.reject(x.status);
          }
          return Promise.reject();
        }
    },

    /**
     * Create a new folio
     */
    async updateFolio(updateFolio: UpdateFolio) {
      const folioClient = new FolioClient();

      // prettier-ignore
      try {
          let result = await folioClient.updateFolio(updateFolio);
          const contentIndex = this.storeFolios.findIndex((x) => x.contentItemId === result.contentItemId);

          if (contentIndex > -1) {
            Vue.set(this.storeFolios, contentIndex, result);
          }
          return Promise.resolve();
          
        } catch (x: any) {
          if (x && x.status) {
            return Promise.reject(x.status);
          }
          return Promise.reject();
        }
    },

    /**
     * Retrieve a list of all folios
     */
    async fetchFolios(filters: SummaryFilters | undefined = undefined) {
      if (this.isFetchingFolios) {
        return;
      }

      this.isFetchingFolios = true;
      const folioClient = new FolioClient();

      let items: ContentItemSummaryResult[];
      try {
        // items = await contentsClient.getContentItemSummaries(100, [ContentItemType.Folio], null);
        items = await folioClient.getFolios();

        for (const item of items) {
          const contentIndex = this.storeFolios.findIndex((x) => x.contentItemId === item.contentItemId);

          if (contentIndex === -1) {
            this.storeFolios.push(item);
          } else {
            Vue.set(this.storeFolios, contentIndex, item);
          }
        }
        this.isFetchingFolios = false;
      } catch {
        this.isFetchingFolios = false;
      }
    },
  },
});
