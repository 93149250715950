export const DOCEO_ICONS = {
  ADD: "add.png",
  ADD_ACTIVE: "add_active.png",
  ATTACHMENT: "attachment.png",
  AUTHOR: "author.png",
  AUTHOR_MULTIPLE: "author_multiple.png",
  BACK: "back.png",
  CHAT_BUBBLE: "chat_bubble.png",
  CHAT_BUBBLE_ACTIVE: "chat_bubble_active.png",
  CLOSE: "close.png",
  CONTENT_ARTICLE: "content_article.png",
  CONTENT_DECISION: "content_decision.png",
  CONTENT_DRAFT: "draft.png",
  CONTENT_DRUG: "content_drug.png",
  CONTENT_TRIAL: "content_trial.png",
  CREATE: "folio_create.png",
  DATE: "date.png",
  EDIT: "edit.png",
  FOLIO_PRIVATE: "folio_private.png",
  FOLIO_RESTRICTED: "folio_restricted.png",
  GENDER: "gender.png",
  GREEN_CHECKMARK: "green_checkmark.png",
  LIKE_BLUE: "like_blue.png",
  LIKE_DEFAULT: "like_default.png",
  LIKE_GREEN: "like_green.png",
  LIKE_ORANGE: "like_orange.png",
  LIKE_YELLOW: "like_yellow.png",
  LINK: "link.png",
  MARKETING_BRAIN: "marketing/brain.png",
  MARKETING_CHAT: "marketing/chat.png",
  MARKETING_FACEBOOK: "marketing/facebook.png",
  MARKETING_FOLDER: "marketing/folder.png",
  MARKETING_INSTAGRAM: "marketing/instagram.png",
  MARKETING_LINKEDIN: "marketing/linkedin.png",
  MORE: "more.png",
  MORE_ACTIVE: "more_active.png",
  NOTIFICATION: "notification.png",
  NOTIFICATION_ACTIVE: "notification_active.png",
  REFRESH: "refresh.png",
  REMOVE: "remove.png",
  SAVE_BLUE: "save_blue.png",
  SAVE_DEFAULT: "save_default.png",
  SAVE_FACEBOOK: "save_facebook.png",
  SAVE_GREEN: "save_green.png",
  SAVE_INSTAGRAM: "save_instagram.png",
  SAVE_LINKEDIN: "save_linkedin.png",
  SAVE_ORANGE: "save_orange.png",
  SAVE_PINK: "save_pink.png",
  SAVE_TWITTER: "save_twitter.png",
  SAVE_YELLOW: "save_yellow.png",
  SAVE_YOUTUBE: "save_youtube.png",
  SEARCH: "search.png",
  SOCIAL_FACEBOOK: "facebook.png",
  SOCIAL_INSTAGRAM: "instagram.png",
  SOCIAL_LINKEDIN: "linkedin.png",
  SOCIAL_TWITTER: "twitter.png",
  SOCIAL_YOUTUBE: "youtube.png",
  TO_ORIGINAL: "to_original.png",
  JOURNAL: "journal.png"
};

export default DOCEO_ICONS;
