import _Vue from "vue";
import * as signalR from "@microsoft/signalr";
import { useChatHubStore } from "@/store/chatHubStore";
import { useConversationStore } from "@/store/conversationStore";
import store from "@/store/userStore";
import { BroadcastMessageCommand } from "@/api/signalRModels/BroadcastMessageCommand";

export function ChatHub(Vue: typeof _Vue, options?: any): void {
  const connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(`${process.env.VUE_APP_API_BASE_URL}/chat`, {
      accessTokenFactory: () => {
        return store.getters.accessToken;
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext) => {
        const reconnectDelay = Math.random() * 10000 + 1000;
        let hubStore = useChatHubStore();
        hubStore.SET_HUB_CONNECT_ATTEMPT_COUNT(retryContext.previousRetryCount);

        return reconnectDelay;
      },
    })
    .build();

  connection.onreconnecting(function() {
    let hubStore = useChatHubStore();

    hubStore.SET_IS_CONNECTED_TO_HUB(false);
  });

  connection.onreconnected(function() {
    let hubStore = useChatHubStore();
    hubStore.SET_IS_CONNECTED_TO_HUB(true);
    hubStore.CLEAR_HUB_CONNECT_ATTEMPT_COUNT();
  });

  connection.on("BroadcastMessage", (broadcastMessageCommand: BroadcastMessageCommand) => {
    let conversationStore = useConversationStore();
    conversationStore.receiveMessage(broadcastMessageCommand);
  });

  async function start() {
    let hubStore = useChatHubStore();
    hubStore.SET_CONNECTION(connection);
    try {
      await connection.start();
      hubStore.SET_IS_CONNECTED_TO_HUB(true);
      hubStore.loadChatrooms();
      hubStore.getInvitedChatrooms();
    } catch (err) {
      console.error(err);
      setTimeout(start, 5000);
    }
  }

  async function stop() {
    let hubStore = useChatHubStore();
    await connection.stop();
    hubStore.SET_IS_CONNECTED_TO_HUB(false);
  }

  if (store.getters.isLoggedIn && store.getters.hasDoctorRole) {
    start();
  }

  // Watch the isLoggedIn getter for changes.
  // Start the connection once a user is authenticated, and stop it when they're not.
  store.watch(
    (_, getters) => getters.isLoggedIn,
    (newValue, _) => {
      if (newValue && store.getters.hasDoctorRole) {
        start();
      } else if (!newValue) {
        // The user is no longer authenticated.
        stop();
      }
    }
  );
}
