

































































































































































































import Vue from "vue";
import { CreateUserCommand, ICreateUserCommand, ProblemDetails, UserClient, UserType } from "@/api/DoceoApi";
import InfoDialog from "@/components/userManagement/InfoDialog.vue";

import { validationMixin } from "vuelidate";
import { required, maxLength, email, requiredIf } from "vuelidate/lib/validators";

const mincValidate = (value: string) => {
  if (!value) return true;
  let regexp = new RegExp("[a-zA-Z]{4}[1-9]{8}");
  return regexp.test(value.replaceAll("-", ""));
};

const checkboxAccepted = (value: boolean) => {
  return value;
};

const privacyAccepted = (value: boolean) => {
  return value;
};

const termsAccepted = (value: boolean) => {
  return value;
};

export default Vue.extend({
  name: "RegisterDialog",
  mixins: [validationMixin],
  components: { InfoDialog },
  validations() {
    return {
      firstName: { required, maxLength: maxLength(50) },
      lastName: { required, maxLength: maxLength(50) },
      minc: { mincValidate },
      email: { required, email: (val) => (email as any)(val.toLowerCase()) },
      privacyPolicy: { privacyAccepted },
      termsOfService: { termsAccepted },
      genericCheckbox: { checkboxAccepted },
      school: {
        required: requiredIf(() => {
          return this.userSelectedRole == UserType.MedicalResident;
        }),
      },
    };
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email as string;
    }
    if (this.$route.query.fname) {
      this.firstName = this.$route.query.fname as string;
    }
    if (this.$route.query.lname) {
      this.lastName = this.$route.query.lname as string;
    }
    if (this.$route.query.discussion) {
      this.pendingDiscussionId = this.$route.query.discussion as string;
    }
    if (this.$route.query.folio) {
      this.pendingFolioId = this.$route.query.folio as string;
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    minc: undefined as string | undefined,
    school: undefined as string | undefined,
    userSelectedRole: UserType.Doctor,
    errorMsg: undefined as string | undefined,
    isRegistering: false,
    isConfirmedDialogOpen: false,
    pendingFolioId: null as string | null,
    pendingDiscussionId: null as string | null,
    privacyPolicy: false,
    termsOfService: false,
    genericCheckbox: false,
    userRoleOptions: [
      {
        text: "Doctor",
        value: UserType.Doctor,
      },
      {
        text: "Nurse Practitioner",
        value: UserType.NursePractitioner,
      },
      {
        text: "Medical Resident",
        value: UserType.MedicalResident,
      },
      {
        text: "Medical Student",
        value: UserType.MedicalStudent,
      },
    ],
  }),
  methods: {
    /**
     * Validate the login form and perform a login
     */
    async registerClicked() {
      this.errorMsg = undefined;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      var createUser: ICreateUserCommand = {
        email: this.email,
        firstName: this.firstName ?? "",
        lastName: this.lastName ?? "",
        userType: this.userSelectedRole,
        minc: (this.minc ?? "").replaceAll("-", ""),
        schoolName: this.school,
        pendingDiscussionId: this.pendingDiscussionId ?? undefined,
        pendingFolioId: this.pendingFolioId ?? undefined,
      };

      let createCommand = new CreateUserCommand(createUser);

      try {
        this.isRegistering = true;
        await new UserClient().create(createCommand);
        this.isConfirmedDialogOpen = true;
      } catch (x) {
        if (x instanceof ProblemDetails) {
          this.errorMsg = x.detail;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } finally {
        this.isRegistering = false;
      }
    },
    openLogin() {
      this.$emit("openLogin");
    },

    clearGenericCheckbox() {
      this.genericCheckbox = false;
    },
  },
  computed: {
    isDoctorSelected(): boolean {
      return this.userSelectedRole == UserType.Doctor;
    },
    isNursePractitionerSelected(): boolean {
      return this.userSelectedRole == UserType.NursePractitioner;
    },
    isMedicalResidentSelected(): boolean {
      return this.userSelectedRole == UserType.MedicalResident;
    },
    termsOfServiceUrl(): string {
      let routeData = this.$router.resolve({ name: "TermsOfService" });
      return routeData.href;
    },
    privacyPolicyUrl(): string {
      let routeData = this.$router.resolve({ name: "PrivacyPolicy" });
      return routeData.href;
    },
    /**
     * First name error checking
     */
    firstNameErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First Name is required.");
      return errors;
    },
    /**
     * Last name error checking
     */
    lastNameErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last Name is required.");
      return errors;
    },
    /**
     * Email error checking
     */
    emailErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid Email");
      !this.$v.email.required && errors.push("Email is required.");
      return errors;
    },
    /**
     * MINC error checking
     */
    mincErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.minc.$dirty) return errors;
      !this.$v.minc.mincValidate && errors.push("Invalid MINC");

      return errors;
    },
    schoolErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.school.$dirty) return errors;
      !this.$v.school.required && errors.push("School name is required.");
      return errors;
    },
    /** Privacy Policy Errors */
    privacyPolicyErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.privacyPolicy.$dirty) return errors;
      !this.$v.privacyPolicy.privacyAccepted && errors.push("You must accept the privacy policy");

      return errors;
    },
    /** Terms of Service Errors */
    termsOfServiceErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.termsOfService.$dirty) return errors;
      !this.$v.termsOfService.termsAccepted && errors.push("You must accept the terms and conditions");

      return errors;
    },
    genericCheckboxErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.genericCheckbox.$dirty) return errors;
      !this.$v.genericCheckbox.checkboxAccepted && errors.push("Required");
      return errors;
    },
    genericCheckboxMessage(): string {
      switch (this.userSelectedRole) {
        case UserType.Doctor:
          return "I am a licensed doctor in Canada";
        case UserType.MedicalResident:
          return "I am a medical resident in Canada";
        case UserType.NursePractitioner:
          return "I am a licensed nurse practitioner in Canada";
        case UserType.MedicalStudent:
          return "I am a medical student in Canada";
        default:
          return "I am a license doctor in Canada";
      }
    },
  },
});
