import axios, { AxiosResponse } from "axios";
import store from "@/store/userStore";

export default abstract class AxiosClient {
  public constructor() {}

  protected transformOptions(options: any) {
    // If there is an access token in the store, attach it to the request
    if (store.getters.accessToken) {
      options.headers["Authorization"] = `Bearer ${store.getters.accessToken}`;
    }

    return Promise.resolve(options);
  }

  protected async transformResult(url: string, response: AxiosResponse, processor: (response: AxiosResponse) => any) {
    // If we receive a 401 response and there is a refresh token. Make a request to refresh the token.
    // If the refresh is a success, we will rerun the previous request,
    // otherwise we will logout and redirect to login
    if (response.status == 401 && store.getters.hasRefreshToken) {
      try {
        return processor(await this.tryRefreshTokenAsync(response));
      } catch {
        this.logoutAndRedirect();
        return processor(response);
      }
    } else {
      return processor(response);
    }
  }

  /**
   * Use the refresh token to try and get a new access token
   */
  async tryRefreshTokenAsync(response: AxiosResponse): Promise<AxiosResponse<any>> {
    await store.dispatch("refreshLogin");
    response.config.headers["Authorization"] = `Bearer ${store.getters.accessToken}`;
    let instance = axios.create();
    return await instance.request(response.config);
  }

  /**
   * Logout and redirect to the login page
   */
  logoutAndRedirect() {
    store.dispatch("logout");
    const router = require("@/router/index");
    router.default.push({ name: "Login" });
  }

  public getBaseUrl(val?: string): string {
    return process.env.VUE_APP_API_BASE_URL!;
  }
}
