/**
 * Helper functions for Google Analytics
 */

import { defineStore } from "pinia";
import { time } from "vue-gtag";

export enum EventTimeName {
  loadTrending = "Load Trending",
  loadFollowing = "Load Following",
  loadInitialDiscover = "Load Initial Discover",
  loadDiscover = "Load Additional Discover",
  loadContentItem = "Load Content Item",
  loadSearchItems = "Load Search Items",
}

export enum EventTimeCategory {
  discoverFeed = "Discover Feed",
  content = "Content",
  search = "Search",
}

export const useAnalyticsStore = defineStore("analytics", {
  state: () => ({}),
  getters: {},
  actions: {
    /**
     * Submits a timing_complete event to google analytics
     */
    async submitEventTimer(startTime: Date, endTime: Date, eventName: EventTimeName, eventCategory: EventTimeCategory) {
      let millisecondsBetween = endTime.getTime() - startTime.getTime();
      time({
        name: eventName,
        value: millisecondsBetween,
        event_category: eventCategory,
      });
    },
  },
});
