import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import VueRouter from "vue-router";
import { createPinia, PiniaVuePlugin } from "pinia";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { DayJsPlugin } from "@/plugins/dayjs/dayjsPlugin";
import { ContentHub } from "@/hubs/contentHub";
import { NotificationHub } from "@/hubs/notificationHub";
import { ChatHub } from "@/hubs/chatHub";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { VueMasonryPlugin } from "vue-masonry";
import store from "@/store/userStore";

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(DayJsPlugin);

Vue.use(VueRouter);
Vue.use(VueMasonryPlugin);
Vue.use(VueMeta);

library.add(fas);
library.add(far);

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  pinia,
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
  },
  router
);

Vue.use(ContentHub);
Vue.use(NotificationHub);
Vue.use(ChatHub);
