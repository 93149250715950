import VueRouter, { RouteConfig } from "vue-router";

const Discover = () => import("../views/Discover.vue");
const Folio = () => import("../views/Folio.vue");
const DoctorSearch = () => import("../views/DoctorSearch.vue");
const Profile = () => import("../views/Profile.vue");
const ContentItemDetails = () => import("@/views/ContentItemDetails.vue");
const Search = () => import("@/views/Search.vue");
const DrugGroup = () => import("@/views/DrugGroup.vue");
const Discussions = () => import("@/views/Discussions.vue");
const MyContent = () => import("@/views/MyContent.vue");
const CreateContent = () => import("@/views/CreateContent.vue");
const AccountManagement = () => import("@/views/AccountManagement.vue");
const Landing = () => import("@/views/Landing.vue");
const Home = () => import("@/views/marketing/Home.vue");
const TermsOfService = () => import("@/views/marketing/TermsOfService.vue");
const PrivacyPolicy = () => import("@/views/marketing/PrivacyPolicy.vue");
const AboutUs = () => import("@/views/marketing/AboutUs.vue");
const ContactUs = () => import("@/views/marketing/ContactUs.vue");

import store from "@/store/userStore";

import SearchType from "@/constants/searchType";
import Roles from "@/constants/roles";

/**
 * By default all routes will require the user to be logged in.
 * If the page should be visible to all vistors, add a meta field to the route with the noAuthRequired flag set to true. (See Login route for an example)
 */

/**
 * If you are updating or adding a new router that is public, the path also needs to be added to the path.js file to ensure it's picked up in the sitemap.xml
 */

export enum ActiveNav {
  Discover,
  Folio,
  DoctorSearch,
  MyContent,
  MarketingHome,
  MarketingAboutUs,
  MarketingContact,
}

const routes: Array<RouteConfig> = [];

if (process.env.VUE_APP_INCLUDE_APP == "true") {
  routes.push(
    {
      path: "/",
      redirect: () => (store.getters.isLoggedIn ? { name: "Discover" } : { name: "Home" }),
    },
    {
      path: "/Home/:dialog?",
      name: "Home",
      component: Home,
      meta: {
        noAuthRequired: true,
        searchType: SearchType.None,
        activeNav: ActiveNav.MarketingHome,
        isMarketing: true,
      },
    },
    {
      path: "/Login",
      name: "Login",
      redirect: "/Home/Login",
    },
    {
      path: "/discover",
      name: "Discover",
      component: Discover,
      meta: {
        searchType: SearchType.Content,
        activeNav: ActiveNav.Discover,
      },
    },
    {
      path: "/Profile/:userId?",
      name: "Profile",
      component: Profile,
      meta: {
        searchType: SearchType.None,
      },
    },
    {
      path: "/folio",
      name: "Folio",
      component: Folio,
      meta: {
        searchType: SearchType.Content,
        activeNav: ActiveNav.Folio,
        allowedRoles: [Roles.Doctor, Roles.Admin],
      },
    },
    {
      path: "/discussions",
      name: "Discussions",
      component: Discussions,
      meta: {
        searchType: SearchType.None,
        allowedRoles: [Roles.Doctor],
      },
    },
    {
      path: "/doctor-search",
      name: "DoctorSearch",
      component: DoctorSearch,
      meta: {
        searchType: SearchType.None,
        activeNav: ActiveNav.DoctorSearch,
        allowedRoles: [Roles.Doctor, Roles.Admin],
      },
    },
    {
      path: "/contents/:contentItemId",
      name: "ContentItemDetails",
      component: ContentItemDetails,
      props: true,
      meta: {
        searchType: SearchType.Content,
      },
    },
    {
      path: "/contents",
      name: "Search",
      component: Search,
      meta: {
        searchType: SearchType.Content,
      },
    },
    {
      path: "/drug/:name",
      name: "DrugGroup",
      component: DrugGroup,
      props: true,
      meta: {
        searchType: SearchType.Content,
      },
    },
    {
      path: "/my-posts",
      name: "MyContent",
      component: MyContent,
      meta: {
        searchType: SearchType.None,
        activeNav: ActiveNav.MyContent,
      },
    },
    {
      path: "/create-content/:contentItemType?",
      name: "CreateContent",
      component: CreateContent,
      meta: {
        activeNav: ActiveNav.MyContent,
        allowedRoles: [Roles.Doctor, Roles.Admin, Roles.VoiceOf],
      },
    },
    {
      path: "/account-management/:accountType",
      name: "AccountManagement",
      component: AccountManagement,
      meta: {
        allowedRoles: [Roles.Admin],
      },
    },
    {
      path: "/landing",
      name: "landing",
      component: Landing,
      meta: {
        noAuthRequired: true,
        searchType: SearchType.None,
      },
    },
    {
      path: "/terms-of-service",
      name: "TermsOfService",
      component: TermsOfService,
      meta: {
        noAuthRequired: true,
        isMarketing: true,
      },
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
      meta: {
        noAuthRequired: true,
        isMarketing: true,
      },
    },
    {
      path: "/about-us",
      name: "AboutUs",
      component: AboutUs,
      meta: {
        noAuthRequired: true,
        isMarketing: true,
        activeNav: ActiveNav.MarketingAboutUs,
      },
    },
    {
      path: "/contact-us",
      name: "ContactUs",
      component: ContactUs,
      meta: {
        noAuthRequired: true,
        isMarketing: true,
        activeNav: ActiveNav.MarketingContact,
      },
    },
    {
      // handles invalid routes, must be last
      path: "*",
      redirect: "/discover",
    }
  );
} else {
  routes.push(
    {
      path: "/",
      name: "landing",
      component: Landing,
      meta: {
        noAuthRequired: true,
      },
    },
    {
      path: "*",
      redirect: "/",
    }
  );
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  if (to.meta && to.meta.noAuthRequired) {
    next();
    return;
  }
  if (store.getters.isLoggedIn) {
    await store.dispatch("checkTokenExpiringSoon");

    if (to.meta?.allowedRoles) {
      if (!store.getters.roles?.some((role: string) => to.meta!.allowedRoles.includes(role))) {
        next("/");
        return;
      }
    }
    next();
  } else {
    next("/Login");
  }
});

router.afterEach((to, from) => {
  if (to.meta?.isMarketing === true) {
    window.scrollTo(0, 0); // scroll to top when navigating to a marketing page
  }
});

export default router;
