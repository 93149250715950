/**
 * Managing everything related to the logged in user
 */

import { ContentItemSummaryResult, ContentItemType, ContentItemVersionEntryResult, ContentsClient } from "@/api/DoceoApi";
import { defineStore } from "pinia";
import Vue from "vue";

import { EventTimeCategory, EventTimeName, useAnalyticsStore } from "@/store/analyticsStore";

interface SummaryFilters {
  includeContentTypes: ContentItemType[] | null | undefined;
  excludeContentTypes: ContentItemType[] | null | undefined;
}

export const useContentStore = defineStore("content", {
  state: () => ({
    loggedIn: false,
    storeSummaries: [] as ContentItemSummaryResult[],
    versions: {} as Record<string, ContentItemVersionEntryResult[]>,
    storeNewContentAdded: false,
    offset: 0,
    storeContentItemFilter: [] as ContentItemType[],
    storeTrending: [] as ContentItemSummaryResult[],
    storeFollowed: [] as ContentItemSummaryResult[],
  }),
  getters: {
    newContentAdded: (state) => state.storeNewContentAdded,
    summaries: (state) => state.storeSummaries as ContentItemSummaryResult[],
    version: (state) => state.versions,
    getVersionHistory: (state) => (contentItemId: string) => state.versions[contentItemId] as ContentItemVersionEntryResult[],
    trending: (state) => state.storeTrending,
    followed: (state) => state.storeFollowed,
    filters: (state) => state.storeContentItemFilter,
  },
  actions: {
    async resetSummaries() {
      this.storeSummaries = [];
      this.offset = 0;
    },
    /**
     * Retrieve a list of summaries
     */
    async fetchSummaries() {
      const contentsClient = new ContentsClient();
      let analyticsStore = useAnalyticsStore();

      let items: ContentItemSummaryResult[];

      let count = 40;
      let firstCount = 10;
      let offset = this.offset;

      let timeName: EventTimeName = EventTimeName.loadDiscover;

      if (offset == 0) {
        count = firstCount;
        firstCount = 0;
        timeName = EventTimeName.loadInitialDiscover;
      }

      let startTime = new Date();
      items = await contentsClient.discoverContent(count, firstCount, offset, this.storeContentItemFilter);
      analyticsStore.submitEventTimer(startTime, new Date(), timeName, EventTimeCategory.discoverFeed);

      this.offset += count;

      for (const item of items) {
        this.storeSummaries.push(item);
      }
      this.storeNewContentAdded = false;
    },

    async fetchVersionHistory(contentItemId: string) {
      const contentsClient = new ContentsClient();
      const versions = await contentsClient.getContentItemVersions(contentItemId);

      Vue.set(this.versions, contentItemId, [versions]);
    },

    async setSummaryFilter(summaries: ContentItemType[]) {
      this.storeContentItemFilter = summaries;
      this.storeSummaries = [];
      this.offset = 0;
      await this.fetchSummaries();
    },

    async fetchTrending(count: number = 10) {
      const contentsClient = new ContentsClient();
      const analyticsStore = useAnalyticsStore();
      let startTime = new Date();
      this.storeTrending = await contentsClient.getTrending(count);
      analyticsStore.submitEventTimer(startTime, new Date(), EventTimeName.loadTrending, EventTimeCategory.discoverFeed);
    },

    async fetchFollowed(count: number = 10) {
      const contentsClient = new ContentsClient();
      const analyticsStore = useAnalyticsStore();
      let startTime = new Date();
      this.storeFollowed = await contentsClient.getRecentFollowedContent(count);
      analyticsStore.submitEventTimer(startTime, new Date(), EventTimeName.loadFollowing, EventTimeCategory.discoverFeed);
    },
  },
});
