
























































import Vue from "vue";
import { ForgotPasswordCommand, IForgotPasswordCommand, ProblemDetails, UserClient } from "@/api/DoceoApi";
import InfoDialog from "@/components/userManagement/InfoDialog.vue";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "ForgotPasswordDialog",
  mixins: [validationMixin],
  components: { InfoDialog },
  validations: {
    email: { required, email: (val) => (email as any)(val.toLowerCase()) },
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    email: "",
    errorMsg: undefined as string | undefined,
    isSubmitting: false,
    isInfoDialogOpen: false,
  }),
  methods: {
    /**
     * Called when the user closes the result dialog
     */
    dialogClosed() {
      this.isInfoDialogOpen = false;
      this.openLogin();
    },
    /**
     * Validate the form and perform a request to reset password.
     * When the request finishes, the dialog will open if it was a success response
     */
    async requestLinkClicked() {
      this.errorMsg = undefined;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let userClient = new UserClient();
      let forgotPassword: IForgotPasswordCommand = {
        email: this.email,
      };
      let forgotPasswordCommand = new ForgotPasswordCommand(forgotPassword);
      try {
        await userClient.forgotPassword(forgotPasswordCommand);
        this.isInfoDialogOpen = true;
      } catch (x) {
        if (x instanceof ProblemDetails) {
          this.errorMsg = x.detail;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    openLogin() {
      this.$emit("openLogin");
    },
  },
  computed: {
    /**
     * Email error checking
     */
    emailErrors() {
      const errors = [] as string[];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid Email");
      !this.$v.email.required && errors.push("Email is required.");
      return errors;
    },
  },
});
