/**
 * Managing everything related to profiles
 */

import { AreaOfInterestDto, IUpdateProfileDto, ProfileClient, ProfileDto, UpdateProfileDto, SpecialtyDto } from "@/api/DoceoApi";
import { defineStore } from "pinia";

import store from "@/store/userStore";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    storeProfile: null as ProfileDto | null,
    storeAreasOfInterest: [] as AreaOfInterestDto[],
    storeAllSpecialties: [] as SpecialtyDto[],
  }),
  getters: {
    profile: (state) => state.storeProfile,
    areasOfInterest: (state) => state.storeAreasOfInterest,
    allSpecialties: (state) => state.storeAllSpecialties,
    profilePictureAttachmentId: (state) => state.storeProfile?.profilePictureAttachmentId,
  },
  actions: {
    async getProfileAsync() {
      let profileClient = new ProfileClient();
      let profile = await profileClient.getProfile(store.getters.userId);
      this.storeProfile = profile;
    },
    async updateProfile(updatedProfile: IUpdateProfileDto) {
      let profileClient = new ProfileClient();
      await profileClient.updateProfile(new UpdateProfileDto(updatedProfile));
      await this.getProfileAsync();
    },
    async uploadProfilePicture(file: File) {
      let fileParameter = {
        data: file,
        fileName: file.name
      };

      let profileClient = new ProfileClient();
      await profileClient.uploadProfilePicture(fileParameter);
      await this.getProfileAsync();
    },
    async getAreasOfInterests() {
      if (this.storeAreasOfInterest.length == 0) {
        let profileClient = new ProfileClient();
        this.storeAreasOfInterest = await profileClient.getAreasOfInterest();
      }
    },
    async getAllSpecialties() {
      if (this.storeAllSpecialties.length == 0) {
        let profileClient = new ProfileClient();
        this.storeAllSpecialties = await profileClient.getAllSpecialties();
      }
    }
  },
});
