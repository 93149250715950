import _Vue from "vue";
import * as signalR from "@microsoft/signalr";
import { useHubStore } from "@/store/hubStore";
import store from "@/store/userStore";

export function ContentHub(Vue: typeof _Vue, options?: any): void {
  const connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(`${process.env.VUE_APP_API_BASE_URL}/contentHub`, {
      accessTokenFactory: () => {
        return store.getters.accessToken;
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext) => {
        const reconnectDelay = Math.random() * 10000 + 1000;
        let hubStore = useHubStore();
        hubStore.SET_HUB_CONNECT_ATTEMPT_COUNT(retryContext.previousRetryCount);

        return reconnectDelay;
      },
    })
    .build();

  connection.onreconnecting(function() {
    let hubStore = useHubStore();
    hubStore.SET_IS_CONNECTED_TO_HUB(false);
  });

  connection.onreconnected(function() {
    let hubStore = useHubStore();
    hubStore.SET_IS_CONNECTED_TO_HUB(true);
    hubStore.CLEAR_HUB_CONNECT_ATTEMPT_COUNT();
  });

  connection.on("NewPendingFolioInvite", () => {});

  async function start() {
    let hubStore = useHubStore();
    try {
      if (connection.state != signalR.HubConnectionState.Connected) {
        await connection.start();
        hubStore.SET_IS_CONNECTED_TO_HUB(true);
      }
    } catch (err) {
      console.error(err);
      setTimeout(start, 5000);
    }
  }

  async function stop() {
    let hubStore = useHubStore();
    await connection.stop();
    hubStore.SET_IS_CONNECTED_TO_HUB(false);
  }

  if (store.getters.isLoggedIn) {
    start();
  }

  // Watch the isLoggedIn getter for changes.
  // Start the connection once a user is authenticated, and stop it when they're not.
  // store.watch(
  //   (_, getters) => getters.isLoggedIn,
  //   (newValue, _) => {
  //     if (newValue) {
  //       // The user is authenticated.
  //       start();
  //     } else if (!newValue) {
  //       // The user is no longer authenticated.
  //       stop();
  //     }
  //   }
  // );
}
