







import Vue from "vue";
import { mapActions } from "pinia";
import { useAttachmentStore } from "@/store/attachmentStore";

/**
 * This is a custom component for displaying images.
 * This will show a skelton loader while the image is loading
 */
export default Vue.extend({
  created() {
    this.loadAttachment();
  },
  data: () => ({
    img: null as string | null,
  }),
  watch: {
    attachmentId: function() {
      this.loadAttachment();
    },
  },
  props: {
    attachmentId: {
      required: true,
      type: String,
    },
    imgHeight: {
      default: "",
      type: String,
    },
    imgWidth: {
      default: "",
      type: String,
    },
    contain: {
      default: false,
      type: Boolean,
    },
    rounded: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions(useAttachmentStore, ["getAttachmentAsync"]),
    /**
     * Loads the attachment if one exists.
     */
    async loadAttachment() {
      let attachment = await this.getAttachmentAsync(this.attachmentId);
      var urlCreator = window.URL || window.webkitURL;
      this.img = urlCreator.createObjectURL(attachment?.data);
    },
  },
  computed: {},
});
