












































import Vue from "vue";
import SetPassword from "@/components/userManagement/PasswordFields.vue";
import { Validation, validationMixin } from "vuelidate";
import { ValidationProperties, ValidationGroups } from "vue/types/vue";
import { ISetPasswordCommand, SetPasswordCommand, UserClient, ProblemDetails } from "@/api/DoceoApi";
import InfoDialog from "@/components/userManagement/InfoDialog.vue";

export default Vue.extend({
  name: "VerifyEmailDialog",
  mixins: [validationMixin],
  components: { SetPassword, InfoDialog },
  created() {
    if (this.$route.query.t && this.$route.query.e) {
      this.token = this.$route.query.t as string;
      this.email = this.$route.query.e as string;
    } else {
      this.$router.push({ name: "Home" }); // TODO: Display error?
    }
  },
  validations: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    token: "",
    email: "",
    isSubmitting: false,
    errorMsg: undefined as string | undefined,
    isInfoDialogOpen: false,
  }),
  methods: {
    /**
     * Verify the user's email and set their password
     */
    async buttonClicked() {
      this.$v.$touch();
      var childForm: ValidationProperties<Vue> & ValidationGroups & Validation = (this.$refs.mychild as any).$v;
      childForm.$touch();
      this.errorMsg = undefined;

      if (this.$v.$invalid || childForm.$invalid) {
        return;
      }

      let setPassword: ISetPasswordCommand = {
        token: this.token,
        email: this.email,
        password: childForm.password?.$model,
      };

      try {
        this.isSubmitting = true;
        let result = await new UserClient().setPassword(new SetPasswordCommand(setPassword));

        if (result.succeeded) {
          this.isInfoDialogOpen = true;
        } else if (result.errors && result.errors.length > 0) {
          this.errorMsg = result.errors[0].description;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } catch (x) {
        if (x instanceof ProblemDetails) {
          this.errorMsg = x.detail;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    openLogin() {
      this.$emit("openLogin");
    },
  },
  computed: {},
});
