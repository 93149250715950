/**
 * Managing everything related to the logged in user
 */

import { FolioClient, IRefreshTokenCommand, PendingFolioInvite, RefreshTokenCommand, TokenCommand, UserClient, UserModel } from "@/api/DoceoApi";
import jwt_decode from "jwt-decode";
import { AccessToken } from "@/models/accessToken";
import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import { useProfileStore } from "@/store/profileStore";
import { useAttachmentStore } from "@/store/attachmentStore";
import { useChatHubStore } from "@/store/chatHubStore";
import { useContentStore } from "@/store/contentStore";
import { useConversationStore } from "@/store/conversationStore";
import { useErrorStore } from "@/store/errorStore";
import { useFolioStore } from "@/store/folioStore";
import { useHubStore } from "@/store/hubStore";

import dayjs from "dayjs";
import Roles from "@/constants/roles";

interface UsernamePassword {
  username: string;
  password: string;
}

Vue.use(Vuex);

export class RootState {
  loggedIn: boolean = false;
  accessToken: string | null = null;
  accessTokenExpiry: string | null = null;
  refreshToken: string | null = null;
  user: UserModel | null = null;
  pendingFolioInvites: PendingFolioInvite[] = [];
  refreshRouteName: string | null = null;
  roles: Roles[] | null = null;
}

// Only store the 5 state variables as necessary to local storage
let persistantStorageOptions = {
  key: "doceo",
  paths: ["loggedIn", "accessToken", "accessTokenExpiry", "refreshToken", "roles"],
};

export default new Vuex.Store<RootState>({
  plugins: [createPersistedState(persistantStorageOptions)],
  state: {
    pendingFolioInvites: [] as PendingFolioInvite[],
    loggedIn: false,
    accessToken: null as string | null,
    accessTokenExpiry: null as string | null,
    refreshToken: null as string | null,
    user: null as UserModel | null,
    refreshRouteName: null as string | null,
    roles: null as Roles[] | null,
  },
  getters: {
    refreshRouteName: (state) => state.refreshRouteName,
    accessToken: (state) => state.accessToken,
    accessTokenExpiry: (state) => state.accessTokenExpiry,
    refreshToken: (state) => state.refreshToken,
    isLoggedIn: (state) => state.loggedIn,
    pendingFolioInvites: (state) => state.pendingFolioInvites,
    roles: (state) => state.roles,
    userId: (state): string => {
      if (!state.accessToken) {
        return "";
      }

      var accessToken = (state.accessToken as unknown) as string; // Weird issue with the vue-cli. When the app runs this line is failing if we don't covert to unkown first
      let decoded = jwt_decode<AccessToken>(accessToken);
      return decoded.id;
    },
    isUserSet: (state): boolean => (state.user ? true : false),
    name: (state): string => {
      if (!state.user) {
        return "";
      }
      return `${state.user.firstName} ${state.user.lastName}`;
    },
    firstName: (state): string => {
      if (!state.user) {
        return "";
      }
      return `${state.user.firstName}`;
    },
    lastName: (state): string => {
      if (!state.user) {
        return "";
      }
      return `${state.user.lastName}`;
    },
    hasRefreshToken: (state): boolean => state.refreshToken != null,
    hasAdminRole: (state): boolean => state.roles?.includes(Roles.Admin) ?? false,
    hasVoiceOfRole: (state): boolean => state.roles?.includes(Roles.VoiceOf) ?? false,
    hasSponsorRole: (state): boolean => state.roles?.includes(Roles.Sponsor) ?? false,
    hasDoctorRole: (state): boolean => state.roles?.includes(Roles.Doctor) ?? false,
  },
  actions: {
    async setRefreshRouteName({ commit }, name: string | null) {
      commit("setRefreshingRouteName", name);
    },
    async removeInvite({ commit }, id: string) {
      commit("removePendingInvite", id);
    },
    /**
     * Retrieve a list of pending folio invites
     */
    async getPendingInvites({ commit, dispatch, getters }) {
      let folioClient = new FolioClient();
      let pendingInvites = await folioClient.getPendingInvites(getters.userId);
      commit("setPendingInvites", pendingInvites);
    },
    async getUserAsync({ commit, dispatch, getters }) {
      let userClient = new UserClient();
      let user = await userClient.get(getters.userId);
      commit("setUser", user);
      commit("setRoles", user.roles);
      const profileStore = useProfileStore();
      await profileStore.getProfileAsync();
    },
    /**
     * Check whether the JWT is valid
     * The axios client is set up to refresh it if this comes back with a 401
     */
    async checkToken({ commit, state }) {
      let userClient = new UserClient();
      await userClient.checkToken();
    },
    /**
     * Checks if the JWT is expiring soon (next 5 minutes)
     * Refreshes it if it is expiring soon
     * It is possible to parse this information from the JWT,
     * but if the server and client time are too different, it won't work.
     */
    async checkTokenExpiringSoon({ commit, state, dispatch }) {
      if (
        state.accessTokenExpiry &&
        dayjs
          .utc(state.accessTokenExpiry)
          .subtract(5, "minute")
          .isBefore(dayjs.utc())
      ) {
        await dispatch("refreshLogin");
      }
    },
    /**
     * Perform a refresh token request
     */
    async refreshLogin({ commit, state }) {
      let userClient = new UserClient();

      let refreshToken: IRefreshTokenCommand = {
        refreshToken: state.refreshToken ?? "",
      };
      let refreshTokenCommand = new RefreshTokenCommand(refreshToken);

      var tokenResponse = await userClient.refreshToken(refreshTokenCommand);

      let accessTokenExpiry = dayjs
        .utc()
        .add(tokenResponse.expiresIn, "minute")
        .toISOString();

      commit("setAccessToken", tokenResponse.accessToken ?? null);
      commit("setAccessTokenExpiry", accessTokenExpiry);
      commit("setRefreshToken", tokenResponse.refreshToken ?? null);
      commit("setLoggedIn", true);

      return tokenResponse;
    },
    /**
     * Perform a login.
     */
    async login({ commit, state, dispatch }, usernamePassword: UsernamePassword) {
      let userClient = new UserClient();

      let tokenCommand = new TokenCommand();
      tokenCommand.username = usernamePassword.username;
      tokenCommand.password = usernamePassword.password;
      var tokenResponse = await userClient.token(tokenCommand);

      let accessTokenExpiry = dayjs
        .utc()
        .add(tokenResponse.expiresIn, "minute")
        .toISOString();

      commit("setAccessToken", tokenResponse.accessToken ?? null);
      commit("setAccessTokenExpiry", accessTokenExpiry);
      commit("setRefreshToken", tokenResponse.refreshToken ?? null);
      await dispatch("getUserAsync");
      dispatch("getPendingInvites");
      commit("setLoggedIn", true);

      return tokenResponse;
    },
    /**
     * Perform a logout. This needs to return a promise as we won't want to let the user think they are logged
     */
    async logout({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        commit("setAccessToken", null);
        commit("setRefreshToken", null);
        commit("setAccessTokenExpiry", null);
        commit("setLoggedIn", false);
        commit("setUser", null);
        const attachmentStore = useAttachmentStore();
        attachmentStore.$reset();
        const chatHubStore = useChatHubStore();
        if (chatHubStore.isConnected) {
          chatHubStore.connection?.stop();
        }
        chatHubStore.$reset();
        const contentStore = useContentStore();
        contentStore.$reset();
        const conversationStore = useConversationStore();
        conversationStore.$reset();
        const errorStore = useErrorStore();
        errorStore.$reset();
        const folioStore = useFolioStore();
        folioStore.$reset();
        const hubStore = useHubStore();
        hubStore.$reset();
        const profileStore = useProfileStore();
        profileStore.$reset();

        resolve(true);
      });
    },
  },
  mutations: {
    setRefreshingRouteName(state, name: string | null) {
      state.refreshRouteName = name;
    },
    setLoggedIn(state, loggedIn: boolean) {
      state.loggedIn = loggedIn;
    },
    setRefreshToken(state, refreshToken: string | null) {
      state.refreshToken = refreshToken;
    },
    setAccessToken(state, accessToken: string | null) {
      state.accessToken = accessToken;
    },
    setAccessTokenExpiry(state, accessTokenExpiry: string | null) {
      state.accessTokenExpiry = accessTokenExpiry;
    },
    setUser(state, user: UserModel | null) {
      state.user = user;
    },
    setRoles(state, roles: Roles[] | null) {
      state.roles = roles;
    },
    setPendingInvites(state, pendingInvites: PendingFolioInvite[]) {
      state.pendingFolioInvites = pendingInvites;
    },
    removePendingInvite(state, id: string) {
      const contentIndex = state.pendingFolioInvites.findIndex((x) => x.id === id);
      if (contentIndex > -1) {
        state.pendingFolioInvites.splice(contentIndex, 1);
      }
    },
  },
});
