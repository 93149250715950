/**
 * Managing everything regarding errors.
 * Any error that is added to this store will be displayed as a snackbar in the app
 */

import { defineStore } from "pinia";

export const useErrorStore = defineStore("error", {
  state: () => ({
    storeError: null as string | null,
    storeMessage: null as string | null,
  }),
  getters: {
    error: (state) => state.storeError,
    message: (state) => state.storeMessage,
  },
  actions: {
    /**
     * Add a new error. This will display the error as a snackbar in the app for 2 seconds.
     */
    async addError(error: string) {
      this.storeError = error;
      setTimeout(() => (this.storeError = null), 2000);
    },
    /**
     * Clear the error. This will force the snackbar to close immediately.
     */
    async clearError() {
      this.storeError = null;
    },
    /**
     * Add a new message. This will display the meassage as a snackbar in the app for 2 seconds.
     */
    async addMessage(message: string) {
      this.storeMessage = message;
      setTimeout(() => (this.storeMessage = null), 2000);
    },
    /**
     * Clear the error. This will force the snackbar to close immediately.
     */
    async clearMessage() {
      this.storeMessage = null;
    },
  },
});
