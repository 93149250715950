/**
 * Managing the information related to conversations
 */

import Vue from "vue";
import { defineStore } from "pinia";

import { BroadcastMessageCommand } from "@/api/signalRModels/BroadcastMessageCommand";
import { ChatroomModel } from "@/api/signalRModels/ChatRoomModel";
import { ChatroomType } from "@/api/DoceoApi";
import { CreateChatroomCommand } from "@/api/signalRModels/CreateChatroomCommand";
import { GetMessageQuery } from "@/api/signalRModels/GetMessageQuery";
import { JoinChatroomCommand } from "@/api/signalRModels/JoinChatroomCommand";
import { MessageModel } from "@/api/signalRModels/MessageModel";
import { SendMessageCommand } from "@/api/signalRModels/SendMessageCommand";

import { useChatHubStore } from "@/store/chatHubStore";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

/** This interface contains properties that were in the chatroom mockup but have not been added to the chatroom model. */
export interface Discussion extends ChatroomModel {
  hasMultiplePeople?: boolean;
}

export const useConversationStore = defineStore("conversation", {
  state: () => ({
    storeMessages: [] as MessageModel[],
    storeDiscussions: [] as Discussion[],
    storeChatroomId: "",
    storePendingInvitations: [] as ChatroomModel[],
    storeNextCursor: null as string | null,
  }),
  getters: {
    messages: (state): MessageModel[] => state.storeMessages, // Return a list of the latest messages retrieved (This should be the messages of the selected discussion)
    discussions: (state): Discussion[] => state.storeDiscussions, // Return a list of all discussions the user is in
    pendingChatroomInvites: (state): ChatroomModel[] => state.storePendingInvitations,
    unreadMessageCount: (state): number => state.storeDiscussions.reduce((prev, cur) => prev + cur.unreadMessageCount, 0),
  },
  actions: {
    /**
     * Sets the list of chatrooms.
     */
    async setChatrooms(chatrooms: ChatroomModel[]) {
      this.storeDiscussions = chatrooms;
      this.formatDates();
    },

    /**
     * Adds a new chatroom to the list of chatrooms.
     */
    async addChatroom(chatroom: ChatroomModel) {
      this.storeDiscussions.unshift(chatroom);
    },

    /**
     * Formats the latest message dates for each of the chatrooms.
     * The date will display as the current time if the message was sent on the same day,
     * or the date the message was sent otherwise.
     */
    async formatDates() {
      const today = dayjs().format("MM/DD/YYYY");
      this.storeDiscussions.forEach((chatroom) => {
        if (chatroom.latestMessageDate) {
          const localDate = dayjs.utc(chatroom.latestMessageDate).local();
          const messageDay = localDate.format("MM/DD/YYYY");
          if (messageDay !== today) {
            Vue.set(chatroom, "latestMessageDate", messageDay);
          } else {
            Vue.set(chatroom, "latestMessageDate", localDate.format("h:mm A"));
          }
        }
      });
    },

    /**
     * Sets the current chatroom to the specified chatroomId.
     */
    async setCurrentChatroom(chatroomId: string) {
      if (chatroomId != this.storeChatroomId) {
        this.storeChatroomId = chatroomId;
        if (chatroomId) {
          this.storeMessages = [];
          this.storeNextCursor = null;
          await this.getHistoricalMessages();
          await this.clearUnread();
        }
      }
    },

    /**
     * Clears the unread count for the current chatroom.
     */
    async clearUnread() {
      const chatroom = this.storeDiscussions.find((room) => room.id === this.storeChatroomId);
      if (chatroom) {
        Vue.set(chatroom, "unreadMessageCount", 0);
      }
    },

    /**
     * Retrieve a list of messages for the current chatroom and prepend it to the list of messages.
     * Stores a cursor to load in older messages the next time this function is run.
     */
    async getHistoricalMessages() {
      const query = {
        chatroomId: this.storeChatroomId,
        nextCursor: this.storeNextCursor,
      } as GetMessageQuery;

      const chatHubStore = useChatHubStore();
      const newMessages = await chatHubStore.getMessages(query);
      if (newMessages.length > 0) {
        this.storeMessages.unshift(...newMessages);
        this.storeNextCursor = this.storeMessages[0].createdUtc;
      }
    },

    /**
     * Sends a message to the chatroom.
     */
    async sendMessage(content: string) {
      const command: SendMessageCommand = {
        chatroomId: this.storeChatroomId,
        content: content,
      };
      const chatHubStore = useChatHubStore();
      await chatHubStore.sendMessage(command);
    },

    /**
     * Adds a new message to the list of messages.
     */
    async addMessage(message: MessageModel) {
      this.storeMessages.push(message);
    },

    /**
     * Called when a new message is received.
     * Adds the message to the current chatroom if the message was broadcasted to it,
     * or to the list of unread messages otherwise.
     */
    async receiveMessage(broadcastMessage: BroadcastMessageCommand) {
      const chatroom = this.storeDiscussions.find((room) => room.id === broadcastMessage.chatroomId);
      if (chatroom) {
        Vue.set(chatroom, "latestMessage", broadcastMessage.message);
        Vue.set(chatroom, "latestMessageDate", dayjs().format("h:mm A"));
        if (broadcastMessage.chatroomId == this.storeChatroomId) {
          this.addMessage({
            id: uuidv4(),
            chatroomId: broadcastMessage.chatroomId,
            fromUserId: "",
            fromUserFullName: broadcastMessage.fromUser,
            content: broadcastMessage.message,
            createdUtc: Date(),
          } as MessageModel);
        } else {
          Vue.set(chatroom, "unreadMessageCount", chatroom.unreadMessageCount + 1);
        }
      }
    },

    /**
     * Creates a new chatroom and adds it to the list of chatrooms.
     */
    async createChatroom(name: string) {
      const createChatroomCommand = {
        name: name,
        type: ChatroomType.Private,
      } as CreateChatroomCommand;

      const chatHubStore = useChatHubStore();
      const id = await chatHubStore.createChatroom(createChatroomCommand);

      const newChatroom = { id: id, ...createChatroomCommand } as ChatroomModel;
      this.storeDiscussions.unshift(newChatroom);

      return newChatroom.id;
    },

    /**
     * Leaves the chatroom and removes it from the list.
     */
    async leaveChatroom(chatroom: ChatroomModel) {
      const chatHubStore = useChatHubStore();
      await chatHubStore.leaveChatroom(chatroom.id);
      const index = this.storeDiscussions.indexOf(chatroom);
      if (index !== -1) {
        this.storeDiscussions.splice(index, 1);
      }
    },

    /**
     * Invites the specified user to the current chatroom.
     */
    async inviteUserToChatroom(email: string) {
      const chatHubStore = useChatHubStore();
      await chatHubStore.inviteUserToChatroom(email, this.storeChatroomId);
    },

    /**
     * Joins the specified chatroom and adds it to the list of chatrooms.
     */
    async joinChatroom(chatroom: ChatroomModel) {
      const joinChatroomCommand = {
        chatroomId: chatroom.id,
        privateChatroomInvitationToken: chatroom.inviteToken,
      } as JoinChatroomCommand;

      const chatHubStore = useChatHubStore();
      await chatHubStore.joinChatroom(joinChatroomCommand);

      this.storeDiscussions.unshift(chatroom);
    },

    /**
     * Joins the specified public chatroom if it doesn't already exist and adds it to the list of chatrooms.
     */
    async joinPublicChatroom(chatroomId: string) {
      if (!this.storeDiscussions.find((x) => x.id == chatroomId)) {
        const chatHubStore = useChatHubStore();
        const chatroom = await chatHubStore.joinChatroom({ chatroomId: chatroomId } as JoinChatroomCommand);

        this.storeDiscussions.unshift(chatroom);
      }
    },

    /**
     * Sets the list of pending chatroom invitations.
     */
    async setInvitedChatrooms(invitedChatrooms: ChatroomModel[]) {
      this.storePendingInvitations = invitedChatrooms;
    },

    /**
     * Tells the API that the user has viewed the latest message.
     * This will reset the unread count for the user.
     */
    async readLastMessage(chatroom: Discussion) {
      const chatHubStore = useChatHubStore();
      await chatHubStore.readLastMessage(chatroom.id);
      await this.clearUnread();
    },

    /**
     * Accepts the invitation. Joins the chatroom and removes the invitation.
     */
    async acceptChatroomInvite(chatroom: ChatroomModel) {
      try {
        await this.joinChatroom(chatroom);
        await this.removeInvitation(chatroom);
      } catch {
        Vue.set(chatroom, "hasError", true);
      }
    },

    /**
     * Declines the invitation. Leaves the chatroom and removes the invitation.
     */
    async declineChatroomInvite(chatroom: ChatroomModel) {
      await this.leaveChatroom(chatroom);
      await this.removeInvitation(chatroom);
    },

    /**
     * Removes the invitation from the list of pending invitations.
     */
    async removeInvitation(chatroom: ChatroomModel) {
      const index = this.storePendingInvitations.indexOf(chatroom);
      if (index !== -1) {
        this.storePendingInvitations.splice(index, 1);
      }
    },

    /**
     * Adds an invitation to the pending invitation list.
     */
    async addInvitation(chatroom: ChatroomModel) {
      this.pendingChatroomInvites.push(chatroom);
    },
  },
});
