













import Vue from "vue";

import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "RegisterConfirmedDialogDialog",
  components: {
    DoceoIcon,
  },
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    DOCEO_ICONS,
  }),
  computed: {},
  methods: {},
});
