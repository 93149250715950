/**
 * Store attachments that we are already retrieved. This will allow us to only make a request once for each attachment instead of
 * making a network request every time.
 */
import { AttachmentClient, FileResponse } from "@/api/DoceoApi";

import { defineStore } from "pinia";

interface FileResponseAttachment {
  fileResponse: FileResponse;
  attachmentId: string;
}

export const useAttachmentStore = defineStore("attachment", {
  state: () => ({
    storeAttachments: [] as FileResponseAttachment[],
  }),
  getters: {},
  actions: {
    /**
     * Get's an attachment. First we check if we already have the attachment saved locally, if so we return the local instance.
     * If we don't have a record of the attachment, we reach out to the API for it.
     */
    async getAttachmentAsync(id: string): Promise<FileResponse | null> {
      let index = this.storeAttachments.findIndex((x) => x.attachmentId === id);

      if (index >= 0) {
        return this.storeAttachments[index].fileResponse;
      }

      let attachmentClient = new AttachmentClient();
      let fileResponse = await attachmentClient.get(id);
      if (fileResponse != null) {
        let fileResponseAttachment: FileResponseAttachment = {
          fileResponse: fileResponse,
          attachmentId: id,
        };

        this.storeAttachments.push(fileResponseAttachment);

        return fileResponse;
      }
      return null;
    },
  },
});
