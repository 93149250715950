/**
 * Managing everything related to the logged in user
 */
import * as signalR from "@microsoft/signalr";
import { defineStore } from "pinia";

export const useHubStore = defineStore("hub", {
  state: () => ({
    storeIsLoadingState: false,
    isConnectedToHub: false,
    hubConnectAttemptCount: 0,
  }),
  getters: {
    isLoadingState: (state) => state.storeIsLoadingState,
    isConnected: (state) => state.isConnectedToHub,
  },
  actions: {
    SET_IS_LOADING_STATE(isLoadingState: boolean) {
      this.storeIsLoadingState = isLoadingState;
    },

    SET_IS_CONNECTED_TO_HUB(isConnected: boolean) {
      this.isConnectedToHub = isConnected;
    },

    CLEAR_HUB_CONNECT_ATTEMPT_COUNT() {
      this.hubConnectAttemptCount = 0;
    },

    SET_HUB_CONNECT_ATTEMPT_COUNT(count: number) {
      this.hubConnectAttemptCount = count;
    },
  },
});
