




import Vue from "vue";

export default Vue.extend({
  name: "DoceoIcon",

  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    alt: {
      type: String,
      default: "",
    },
    cursor: {
      type: String,
      default: "pointer",
    },
    hoverIcon: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    isHovered: false,
  }),

  computed: {
    src(): string {
      var icon: string;
      if (this.isHovered && this.hoverIcon != null)
        icon = this.hoverIcon;
      else
        icon = this.icon;
      return require("../assets/icons/" + icon);
    },
  },

  methods: {
    click(e: any) {
      this.$emit("click", e);
    },
    mouseover(e: any) {
      this.$emit("mouseover", e);
      this.isHovered = true;
    },
    mouseleave(e: any) {
      this.$emit("mouseleave", e);
      this.isHovered = false;
    },
  },
});
