

















































import Vue from "vue";
import { mapActions as mapActionsVuex } from "vuex";
import { mapGetters } from "vuex";
import { ProblemDetails } from "@/api/DoceoApi";
import { useChatHubStore } from "@/store/chatHubStore";
import { mapActions } from "pinia";

export default Vue.extend({
  name: "LoginDialog",
  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: "Discover" });
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isFormValid: false,
    loginUsername: "",
    loginPassword: "",
    usernameRules: [(v: string) => !!v || "Username is required"],
    passwordRules: [(v: string) => !!v || "Password is required"],
    errorMsg: undefined as string | undefined,
    isLoggingIn: false,
  }),
  watch: {
    // This will solve our current issue of being redirected back to the login screen
    // when a token is being refreshed. We need to find a better way to do this.
    isLoggedIn: function(val) {
      if (val) {
        this.$router.push({ name: "Discover" });
      }
    },
  },
  methods: {
    ...mapActionsVuex(["login", "setRefreshRouteName"]),
    ...mapActions(useChatHubStore, ["loadChatrooms"]),
    /**
     * Validate the login form and perform a login
     */
    async loginClicked() {
      this.loginForm.validate();
      this.errorMsg = undefined;

      if (!this.isFormValid) {
        return;
      }

      try {
        this.isLoggingIn = true;
        await this.login({ username: this.loginUsername, password: this.loginPassword });
        this.$router.push({ name: "Discover" });
      } catch (x) {
        if (x instanceof ProblemDetails) {
          this.errorMsg = x.detail;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } finally {
        this.isLoggingIn = false;
      }
    },
    openRegister() {
      this.$emit("openRegister");
    },
    openForgotPassword() {
      this.$emit("openForgotPassword");
    },
  },
  computed: {
    ...mapGetters(["refreshRouteName", "isLoggedIn"]),
    /**
     * This takes the loginForm ref and casts it so that there is a validate method description for typescript
     */
    loginForm(): Vue & { validate: () => boolean } {
      return this.$refs.loginForm as Vue & { validate: () => boolean };
    },
  },
});
