/**
 * Managing the signalR chat hub
 */

import * as signalR from "@microsoft/signalr";
import { defineStore } from "pinia";

import { ChatroomModel } from "@/api/signalRModels/ChatRoomModel";
import { CreateChatroomCommand } from "@/api/signalRModels/CreateChatroomCommand";
import { GetMessageQuery } from "@/api/signalRModels/GetMessageQuery";
import { JoinChatroomCommand } from "@/api/signalRModels/JoinChatroomCommand";
import { MessageModel } from "@/api/signalRModels/MessageModel";
import { SendMessageCommand } from "@/api/signalRModels/SendMessageCommand";

import { useConversationStore } from "@/store/conversationStore";

export const useChatHubStore = defineStore("chatHub", {
  state: () => ({
    storeIsLoadingState: false,
    isConnectedToHub: false,
    hubConnectAttemptCount: 0,
    connection: null as signalR.HubConnection | null,
  }),
  getters: {
    isLoadingState: (state) => state.storeIsLoadingState,
    isConnected: (state) => state.isConnectedToHub,
  },
  actions: {
    SET_CONNECTION(connection: signalR.HubConnection) {
      this.connection = connection;
    },

    SET_IS_LOADING_STATE(isLoadingState: boolean) {
      this.storeIsLoadingState = isLoadingState;
    },

    SET_IS_CONNECTED_TO_HUB(isConnected: boolean) {
      this.isConnectedToHub = isConnected;
    },

    CLEAR_HUB_CONNECT_ATTEMPT_COUNT() {
      this.hubConnectAttemptCount = 0;
    },

    SET_HUB_CONNECT_ATTEMPT_COUNT(count: number) {
      this.hubConnectAttemptCount = count;
    },

    async createChatroom(createChatroomCommand: CreateChatroomCommand): Promise<string> {
      return (await this.connection?.invoke<Object>("CreateChatroom", createChatroomCommand)) as string;
    },

    async getInvitedChatrooms(): Promise<ChatroomModel[]> {
      const chatrooms = (await this.connection?.invoke("GetInvitedChatrooms")) as ChatroomModel[];
      const conversationStore = useConversationStore();
      conversationStore.setInvitedChatrooms(chatrooms);
      return chatrooms;
    },

    async getJoinedChatrooms(): Promise<ChatroomModel[]> {
      return (await this.connection?.invoke("GetJoinedChatrooms")) as ChatroomModel[];
    },

    async getMessages(query: GetMessageQuery): Promise<MessageModel[]> {
      return (await this.connection?.invoke("GetMessages", query)) as MessageModel[];
    },

    async inviteUserToChatroom(email: string, chatroomId: string): Promise<void> {
      return this.connection?.invoke("InviteUserToChatroom", email, chatroomId);
    },

    async joinChatroom(command: JoinChatroomCommand): Promise<ChatroomModel> {
      return (await this.connection?.invoke("JoinChatroom", command)) as ChatroomModel;
    },

    async leaveChatroom(chatroomId: string): Promise<void> {
      return this.connection?.invoke("LeaveChatroom", chatroomId);
    },

    async loadChatrooms(): Promise<ChatroomModel[]> {
      const chatrooms = (await this.connection?.invoke("Load")) as ChatroomModel[];
      const conversationStore = useConversationStore();
      conversationStore.setChatrooms(chatrooms);
      return chatrooms;
    },

    async readLastMessage(chatroomId: string): Promise<void> {
      return this.connection?.invoke("ReadLastMessage", chatroomId);
    },

    async resendChatroomInvitationEmail(userId: string, chatroomId: string): Promise<void> {
      return this.connection?.invoke("ResendChatroomInvitationEmail", userId, chatroomId);
    },

    async sendMessage(sendMessageCommand: SendMessageCommand): Promise<void> {
      return this.connection?.invoke("SendMessage", sendMessageCommand);
    },
  },
});
