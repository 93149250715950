



















































import Vue from "vue";

import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import PasswordStrength from "@/components/userManagement/PasswordStrength.vue";

export default Vue.extend({
  name: "Login",
  mixins: [validationMixin],
  components: { PasswordStrength },
  props: {},
  created() {},
  validations: {
    password: {
      required,
      containsUppercase: function(value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },

    verifyPassword: { required, sameAsPassword: sameAs("password") },
  },
  data: () => ({
    password: "",
    verifyPassword: "",
    isRegistering: false,
    errorMsg: undefined as string | undefined,
    isSubmitting: false,
    isInfoDialogOpen: false,
  }),
  methods: {
    async resetPassword() {},

    /**
     * Validate the login form and perform a login
     */
    async buttonClicked() {
      this.$v.$touch();
      this.errorMsg = undefined;

      if (this.$v.$invalid) {
        return;
      }

      this.resetPassword();
    },
  },
  computed: {
    containsUppercase(): Boolean {
      return /[A-Z]/.test(this.password);
    },

    containsLowercase(): Boolean {
      return /[a-z]/.test(this.password);
    },
    containsNumber(): Boolean {
      return /[0-9]/.test(this.password);
    },
    containsSpecial(): Boolean {
      return /[#?!@$%^&*-]/.test(this.password);
    },
    /**
     * Password error checking
     */
    passwordErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required.");
      !this.$v.password.containsUppercase && errors.push("Password must contain an uppercase letter");
      !this.$v.password.containsLowercase && errors.push("Password must contain a lowercase letter");
      !this.$v.password.containsNumber && errors.push("Password must contain a numer");
      !this.$v.password.containsSpecial && errors.push("Password must contain a special character");
      return errors;
    },
    /**
     * Verify password error checking
     */
    verifyPasswordErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.verifyPassword.$dirty) return errors;
      !this.$v.verifyPassword.required && errors.push("Password is required.");
      !this.$v.verifyPassword.sameAsPassword && errors.push("Password must be the same");

      return errors;
    },
  },
});
