import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "faSvg",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#FF6D00",
        secondary: "#002e4b",
        submit: "#FF6D00",
        secondaryButton: "#9a9a9a",
      },
    },
  },
});
